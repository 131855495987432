import { useState } from 'react';
import './Home.css';
import mockup from '../images/mockup.png';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import Loader from '../components/Loader';
import SIPCalculator from '../components/SipCalculator';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
export default function Home() {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        number: '',
        query: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            await setDoc(doc(db, 'queries', formData.name), formData);
            setIsLoading(false);
            alert("Query submitted successfully!");
        }
        catch (error) {
            console.error("Error submitting query: ", error);
        }
    };

   

    return (
        <>
        <div className='container'>
            <Navbar/>
            <section id='Hero'>
                <h1>Your Personalised <br />Financial Planner</h1>
                <p>We finds best mutual funds to invest.</p>
                <button className="btn">INVEST NOW</button>
            </section>
            <img src={mockup} alt="mockup" className='mockup' />
            <section id="about">
                <h2>About Us</h2>
                <p>Welcome to <span style={{ fontFamily: "Unbounded" }}>royFinds</span>!
                    <br />
                    Your trusted financial planning platform, dedicated to helping you find the best mutual funds that align with your financial goals and risk preferences.
                    <br /><br />
                    I'm <b>Dipak Roy</b>, your personal financial planner with over 5 years of experience, here to guide you on your journey toward financial success. Whether you're aiming to grow your wealth, save for retirement, or plan for major life milestones, I’m committed to providing you with expert, tailored advice.
                    <br /><br />
                    At royFinds, we simplify the investment process, helping you select the right mutual funds and track your progress every step of the way. <br />Let’s work together to achieve your financial goals and make your money work for you!
                    <br /><br />
                    <b>ARN : 267402</b> <br />
                    <b>EDIN: E500537</b>
                </p>
            </section>
            <section id='calculator'>
                <SIPCalculator />
            </section>
            <section id='why'>
                <h2>Why Choose Us ?</h2>
                <div className="cards">
                    <div className="card">
                        <span>1</span>
                        <div>
                            <h3>Track your
                                portfolio</h3>
                            <p>We offer a platform where clients can easily track the performance of their mutual fund investments in real-time, providing a clear view of their returns and portfolio composition.</p>
                        </div>
                    </div>
                    <div className="card">
                        <span>2</span>
                        <div>
                            <h3>1 to 1 on call
                                Financial Guidance</h3>
                            <p>We provide dedicated, one-on-one financial guidance tailored to each client’s unique situation. This ensures that you receive individualized support throughout your investment journey.</p>
                        </div>
                    </div>
                    <div className="card">
                        <span>3</span>
                        <div>
                            <h3>Convenience
                                and Ease</h3>
                            <p>We simplify the investment process by handling all the paperwork, KYC, and compliance requirements, making it easy for clients to start investing.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="query">
                <h2>Are you Having <br />any <b>Financial Query?</b></h2>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                    <input
                        type="tel"
                        name="number"
                        placeholder="Number"
                        maxLength={10}
                        value={formData.number}
                        onChange={handleChange}
                        required
                    />
                    <textarea
                        name="query"
                        placeholder="Type your Query ..."
                        value={formData.query}
                        onChange={handleChange}
                        required
                    ></textarea>
                    <button type="submit" className='btn' disabled={isLoading}>{isLoading ? <Loader /> : "Send"}</button>
                </form>
                <span className="note">Ready to start your investment journey?</span>
            </section>

        </div>
           <Footer/>
            </>
    );
}