export default function Footer() {
    return (
        <footer>
            <p>
                Designed and Developed by
                <a href="https://amit-roy.me" target="_blank" rel="noreferrer"> <b>Amit Roy</b>
                </a>
            </p>
            <div className='dis-terms'>
                <a href="/disclaimer">Disclaimer</a>
                <span></span>
                <a href="/terms-and-conditions">Terms and Conditions</a>
            </div>
        </footer>
    );
}