import NavFloatProfile from "./NavFloatProfile";

export default function SecLogo() {
    return (
        <nav>
        <ul className='navbar'>
            <div className="logo-container">
                <li className='logo no-border'><a href="/">royFinds</a></li>
            </div>
        </ul>
        <NavFloatProfile/>
    </nav>
    );
}