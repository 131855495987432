import { useState } from "react";
import upArrow from '../images/upArrow.svg';
import downArrow from '../images/downArrow.svg';
import NavFloatProfile from "./NavFloatProfile";

export default function Navbar() {
    const [showLink, setShowLink] = useState(false);

    return (
        <nav>
                <ul className='navbar'>
                    <div className="logo-container">

                        <li className='logo'><a href="#top">royFinds</a></li>
                        <span onClick={() => setShowLink(!showLink)} style={{ cursor: "pointer" }}>{showLink ? <img src={upArrow} alt="up" height="20px" /> : <img src={downArrow} alt='down' height="20px" />}</span>
                    </div>
                    <div className={`links ${showLink ? 'open' : ''}`}>
                        <li className='link'><a href="#about">About</a></li>
                        <li className='link'><a href="#why">Why Choose Us?</a></li>
                        <li className='link'><a href="#query">Query</a></li>

                    </div>
                </ul>
                <NavFloatProfile />
            </nav>
    );
}