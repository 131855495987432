import { useEffect, useRef, useState } from "react";
import { useAuth } from "../contexts/AuthContext"
import { doc, getDoc, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import profitIcon from "../images/ProfitIcon.svg";
import lossIcon from "../images/lossIcon.svg";
import lasthistory from "../images/last-history.png";
import { useReactToPrint } from 'react-to-print';
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import Logo from "../components/Logo";
import HoldingDetails from "../components/HoldingDetails";



export default function User({ user }) {
  const { currentUser, role } = useAuth();
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const todayDate = new Date();
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
  const userDocRef = role === "admin" ? doc(db, 'users', user.userID) : doc(db, 'users', currentUser.uid);
  const [deleteProductId, setDeleteProductId] = useState(null);

  const [editedValues, setEditedValues] = useState({
    productName: '',
    balanceUnit: '',
    purchaseCost: '',
    currentNav: '',
  });
  const [editingProductId, setEditingProductId] = useState(null);

  useEffect(() => {
    if (!currentUser) return;

    const unsubscribe = onSnapshot(
      userDocRef,
      (doc) => {
        if (doc.exists()) {
          setUserData(doc.data());
        } else {
          console.error("No such user data found!");
        }
      },
      (error) => {
        console.error("Error fetching user data:", error);
      }
    );

    return () => unsubscribe();
    // eslint-disable-next-line
  }, [role, user?.userID, currentUser]);

  useEffect(() => {
    const fetchAndUpdateNAVs = async () => {
      if (userData && userData.products && Object.keys(userData.products).length > 0) {
        try {
          const navPromises = Object.keys(userData.products).map(async (productId) => {
            const product = userData.products[productId];
            const response = await fetch(`https://api.mfapi.in/mf/${product.schemeCode}/latest`);
            const navData = await response.json();
            const nav = navData.data[0]?.nav;

            if (nav) {

              // Update the specific product's currentNav
              await updateDoc(userDocRef, {
                [`products.${productId}.currentNav`]: parseFloat(nav)
              });

            } else {
              console.error(`No NAV data found for scheme code: ${product.schemeCode}`);
            }
          });

          await Promise.all(navPromises);
        } catch (error) {
          console.error("Error updating NAVs:", error.message);
        }
      }
    };

    fetchAndUpdateNAVs();
    // eslint-disable-next-line
  }, [userData]);

  const calculatePL = (product) => {
    const currentValue = product.balanceUnit * product.currentNav;
    const initialValue = product.balanceUnit * calculateAvgCost(product);
    return currentValue - initialValue;
  };
  const calculateCurrentValue = (product) => {
    return product.balanceUnit * product.currentNav;
  };
  const calculateAvgCost = (product) => {
    return product.purchaseCost / product.balanceUnit;
  };

  const totalPL = Object.keys(userData?.products || {}).reduce((acc, key) => {
    const product = userData.products[key];
    return acc + calculatePL(product);
  }, 0);

  const calculateTotalInvestment = (products) => {
    let totalInvestment = 0;
    let totalInvestmentCurrent = 0;
    Object.keys(products || {}).forEach((key) => {
      const product = products[key];
      totalInvestment += (product.balanceUnit * calculateAvgCost(product)) || 0;
      totalInvestmentCurrent += (product.balanceUnit * product.currentNav) || 0;
    });
    return { totalInvestment, totalInvestmentCurrent };
  };

  const { totalInvestment, totalInvestmentCurrent } = calculateTotalInvestment(userData?.products);
  const [newProduct, setNewProduct] = useState({ productName: '', folioNo: '', folioStartedOn: '', balanceUnit: '', currentNav: '', purchaseCost: '', schemeCode: '' });
  const handleAddProduct = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (currentUser && userData) {
        const newProductKey = Object.keys(userData.products).length + 1;
        const updatedUserData = {
          ...userData,
          products: {
            ...userData.products,
            [newProductKey]: newProduct,
          },
        };
        await setDoc(doc(db, 'users', user.userID), updatedUserData);
        setUserData(updatedUserData); // Update local state
        setNewProduct({ productName: '', folioNo: '', folioStartedOn: '', balanceUnit: '', currentNav: '', purchaseCost: '', schemeCode: '' }); // Reset form
        alert('Product added successfully');
        setIsLoading(false);
      }
    } catch (error) {
      alert(error.message);
      setIsLoading(false);
    }
  };

  const handleSave = async (key) => {
    try {
      const updatedProducts = {
        ...userData.products,
        [key]: {
          ...userData.products[key],
          productName: editedValues.productName,
          balanceUnit: editedValues.balanceUnit,
          purchaseCost: editedValues.purchaseCost,
          currentNav: editedValues.currentNav,
        },
      };

      const updatedUserData = {
        ...userData,
        lastUpdatedOn: {
          ...userData.lastUpdatedOn,
          day: todayDate.getDate(),
          month: monthNames[todayDate.getMonth()],
          year: todayDate.getFullYear(),
        },
        products: updatedProducts,
      };

      const formattedDate = `${todayDate.getDate()}-${monthNames[todayDate.getMonth()]}-${todayDate.getFullYear()}`;
      const newHistoryEntry = {
        productKey: key, // Add a key to identify the product
        balanceUnit: editedValues.balanceUnit,
        purchaseCost: editedValues.purchaseCost,
        currentNav: editedValues.currentNav,
        productName: userData.products[key].productName,
        folioNo: userData.products[key].folioNo,
        folioStartedOn: userData.products[key].folioStartedOn,
      };

      const docRef = doc(db, 'users', user.userID);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const existingData = docSnap.data();
        const history = existingData.history || {};
        const currentDateHistory = history[formattedDate] || [];

        // Check if there is an existing entry for this product on the same date
        const productIndex = currentDateHistory.findIndex(entry => entry.productKey === key);

        if (productIndex !== -1) {
          // Update the existing entry
          currentDateHistory[productIndex] = newHistoryEntry;
        } else {
          // Add a new entry
          currentDateHistory.push(newHistoryEntry);
        }

        const updatedHistory = {
          ...history,
          [formattedDate]: currentDateHistory,
        };

        await updateDoc(docRef, {
          ...updatedUserData,
          history: updatedHistory,
        });

        setUserData(updatedUserData);
        setEditingProductId(null);
        alert('Product data updated successfully');
      } else {
        alert('No such document!');
      }
    } catch (error) {
      console.error('Error updating product data:', error);
      alert('Error updating product data. Please try again.');
    }
  };

  const downloadRef = useRef(null);

  const downloadPdf = useReactToPrint({
    content: () => downloadRef.current,
    documentTitle: 'Statements',
    onBeforeGetContent: () => {
      const elementsToHide = document.querySelectorAll('.hide-on-download');
      elementsToHide.forEach(element => element.classList.add('hidden'));
      const elementCenter = document.querySelectorAll('.logo-text');
      elementCenter.forEach(element => element.classList.add('logo-center'));
    },
    onAfterPrint: () => {
      const elementsToHide = document.querySelectorAll('.hide-on-download');
      elementsToHide.forEach(element => element.classList.remove('hidden'));
      const elementCenter = document.querySelectorAll('.logo-text');
      elementCenter.forEach(element => element.classList.remove('logo-center'));
    },
    onPrintError: (error) => {
      alert('Error while downloading the statements', error);
    }
  });

  const navigate = useNavigate();

  const handleRowClick = () => {
    navigate(`/user/${userData?.username}/History`, { state: { userData } });
  };



  const [schemeCode, setSchemeCode] = useState('');
  const [funds, setFunds] = useState([]);
  const [filteredFunds, setFilteredFunds] = useState([]);
  const [error, setError] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    // Fetch all funds once when the component mounts
    const endpoint = 'https://api.mfapi.in/mf';

    fetch(endpoint)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setFunds(data); // Store all funds
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  useEffect(() => {
    // Update filteredFunds based on schemeCode
    if (schemeCode) {
      const filtered = funds.filter(fund => fund.schemeCode === parseInt(schemeCode));
      setFilteredFunds(filtered);
      setShowDropdown(filtered.length > 0); // Show dropdown if there are results
    } else {
      setFilteredFunds([]);
      setShowDropdown(false); // Hide dropdown if no schemeCode
    }
  }, [schemeCode, funds]);

  const handleSchemeCodeChange = (e) => {
    setSchemeCode(e.target.value);
    setNewProduct({ ...newProduct, schemeCode: parseFloat(e.target.value), productName: '' });
  };

  const handleSelect = (fund) => {
    setNewProduct({ ...newProduct, productName: fund.schemeName });
    setShowDropdown(false); // Hide dropdown after selection
  };


  async function deleteProduct(productId) {
    try {
      const updatedProducts = { ...userData.products };
      delete updatedProducts[productId]; // Remove the specific product by its ID

      // Update the products field in the user's document to reflect the deletion
      await updateDoc(userDocRef, {
        products: updatedProducts,
      });
      alert('Product deleted successfully');
    } catch (error) {
      alert("Error deleting product:", error.message);
    }
  }

  const [activeProductId, setActiveProductId] = useState(null);

  const handleProductClick = (key) => {
    setActiveProductId(key); // Set the clicked product as active
  };

  const handleClosePopup = () => {
    setActiveProductId(null); // Close the popup when user clicks outside or on close button
  };

  return (
    <>
      <div className="container" ref={downloadRef}>
        <div className="logo">
          <Logo />
        </div>
        {/* <h2 style={{ marginTop: "25px", color: "#d4d4d4", fontSize: "2rem" }}>Profile</h2> */}
        <div className="user-details">
          <div>
            <p><b>Investor :</b> {userData?.username}</p>
            <p><b>PAN :</b> {userData?.userPan}</p>
          </div>
          <div>
            <p><b>Statement of </b>{todayDate.getDate()} {monthNames[todayDate.getMonth()]} {todayDate.getFullYear()}</p>
            <p><b>Last Buy/Sell on </b>{userData?.lastUpdatedOn.day} {userData?.lastUpdatedOn.month} {userData?.lastUpdatedOn.year}</p>
          </div>
        </div>
        {role === "admin" && (
          <form onSubmit={handleAddProduct} className="addNewForm hide-on-download">
            <h2>Add a new product</h2>
            <div>
              {error ? (
                <p>Error: {error.message}</p>
              ) : (
                <div className="input-field" style={{ position: "relative" }}>
                  <input
                    type="number"
                    id="schemeCode"
                    placeholder="Scheme Code"
                    value={schemeCode}
                    onChange={handleSchemeCodeChange}
                    required
                  />
                  <div className="input-field">
                    <input
                      type="text"
                      placeholder="Product Name"
                      value={newProduct.productName}
                      onChange={(e) => setNewProduct({ ...newProduct, productName: e.target.value })}
                      required
                    />
                  </div>
                  {showDropdown && (
                    <div className="dropdown">
                      {filteredFunds.map((fund, key) => (
                        <div
                          key={key}
                          className="dropdown-item"
                          onClick={() => handleSelect(fund)}>
                          {fund.schemeName}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="input-field">
              <input
                type="text"
                placeholder="Folio No"
                value={newProduct.folioNo}
                onChange={(e) => setNewProduct({ ...newProduct, folioNo: e.target.value })}
                required
              />
            </div>
            <div className="input-field date-input">
              <label htmlFor="folioStartedOn">Folio Started On</label>
              <input
                type="date"
                placeholder="Folio Started On"
                value={newProduct.folioStartedOn}
                onChange={(e) => setNewProduct({ ...newProduct, folioStartedOn: e.target.value })}
                required
              />
            </div>
            <div className="input-field">
              <input
                type="number"
                placeholder="Purchase Cost"
                value={newProduct.purchaseCost}
                onChange={(e) => setNewProduct({ ...newProduct, purchaseCost: parseFloat(e.target.value) })}
                required
              />
            </div>
            <div className="input-field">
              <input
                type="number"
                placeholder="Balance Unit"
                value={newProduct.balanceUnit}
                onChange={(e) => setNewProduct({ ...newProduct, balanceUnit: parseFloat(e.target.value) })}
                required
              />
            </div>
            <button type="submit">{isLoading ? <Loader /> : 'Add'}</button>
          </form>
        )}

        <div className="product">
          <span title="Download Statement" onClick={downloadPdf} className="hide-on-download download-statement">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m.75 12 3 3m0 0 3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
            </svg>
          </span>

          <p className="total-pnl">Total Investment Profit / Loss</p>
          <p className="total-pnl-value">&#8377; {totalPL?.toLocaleString('en-IN', { maximumFractionDigits: 2 })}</p>
          <div className="box hide-on-download">
            {Object.keys(userData?.products || {}).map((key) => (
              <div
                className={`investment-bar investment-${key}`}
                style={{ height: "25px", width: `${((calculatePL(userData.products[key]) / totalPL) * 100).toFixed(2)}%` }}
              ></div>
            ))}
          </div>
          <div className="investments hide-on-download">
            {Object.keys(userData?.products || {}).map((key) => {
              const product = userData.products[key];
              return (
                <div className="investment" key={key}>
                  <div className={`investment-bar investment-${key}`}></div>
                  <div className="investment-details">
                    <p className="investment-type" style={{ cursor: "pointer" }} onClick={() => handleProductClick(key)}>{product.productName}</p>
                    <p className="investment-amount">&#8377; {calculatePL(product)?.toLocaleString('en-IN', { maximumFractionDigits: 2 })}</p>
                    <p className="investment-percentage">
                      {((calculatePL(product) / totalPL) * 100).toFixed(2)}%
                      {calculatePL(product) >= 0 ? <span className="up"><img src={profitIcon} alt="profit" /></span> : <span className="down"><img src={lossIcon} alt="loss" /></span>}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="detail"></div>
          <div className="detail">
            <p>Total Investment Value</p><p>&#8377; {totalInvestment?.toLocaleString('en-IN', { maximumFractionDigits: 2 })}</p>
          </div>
          <div className="detail">
            <p>Invested Current Value</p><p>&#8377; {totalInvestmentCurrent?.toLocaleString('en-IN', { maximumFractionDigits: 2 })}</p>
          </div>
        </div>
        {Object.keys(userData?.products || {}).map((key) => {
          const product = userData.products[key];
          const isEditing = editingProductId === key;
          const isPopupActive = activeProductId === key;
          const confirmDelete = deleteProductId === key;

          return (
            <div className="product" key={key} >
              {isPopupActive && (
                <div className="popup-overlay" onClick={handleClosePopup}>
                  <div className="popup" onClick={(e) => e.stopPropagation()}>
                    {/* Render HoldingDetails component */}
                    <HoldingDetails product={product} onClose={handleClosePopup} />
                  </div>
                </div>
              )}
              {role === "admin" && (<><div className="edit-icon hide-on-download" >
                <button onClick={() => {
                  if (isEditing) {
                    handleSave(key);
                  } else {
                    setEditedValues({ productName: product.productName, balanceUnit: product.balanceUnit, purchaseCost: product.purchaseCost, currentNav: product.currentNav });
                    setEditingProductId(key);
                  }
                }}>
                  {isEditing ? 'Save' : 'Edit'}
                </button>
              </div>
                <div className="edit-icon hide-on-download" style={{ marginRight: "5px" }} >
                  <button onClick={() => {
                    if (confirmDelete) {
                      deleteProduct(key);  // Call your delete function when confirmed
                      setDeleteProductId(null); // Reset after deletion
                    } else {
                      setDeleteProductId(key); // Set the current product for delete confirmation
                    }
                  }}>
                    {confirmDelete ? 'SURE?' : 'DELETE'}
                  </button>
                </div></>
              )}
              <div className="name">
                {isEditing ? (
                  <div className="edit-field">
                    <input
                      type="text"
                      value={editedValues.productName}
                      onChange={(e) => setEditedValues({ ...editedValues, productName: e.target.value })}
                    />
                  </div>
                ) : (
                  <h3 onClick={() => handleProductClick(key)}
                    style={{ cursor: "pointer" }}>{product.productName}</h3>
                )}
              </div>
              <p className="folio">{product.folioNo}</p>
              <div className="detail"><p>Folio Started on</p><p>{product.folioStartedOn}</p></div>
              <hr />
              <b className="porfolio-heading">Portfolio Status</b>
              <div className="details">
                <div className="detail"><p>Balance Unit</p>
                  {isEditing ? (
                    <input
                      type="number"
                      value={editedValues.balanceUnit}
                      onChange={(e) => setEditedValues({ ...editedValues, balanceUnit: parseFloat(e.target.value) })}
                      className="edit-input"

                    />
                  ) : (
                    <p>{product.balanceUnit?.toLocaleString('en-IN', { maximumFractionDigits: 2 })}</p>
                  )}
                </div>

                <div className="detail"><p>Purchase Cost</p>
                  {isEditing ? (
                    <input
                      type="number"
                      value={editedValues.purchaseCost}
                      onChange={(e) => setEditedValues({ ...editedValues, purchaseCost: parseFloat(e.target.value) })}
                      className="edit-input"
                    />
                  ) : (
                    <p>{product.purchaseCost?.toLocaleString('en-IN', { maximumFractionDigits: 2 })}</p>
                  )}
                </div>
                <div className="detail"><p>Avg Cost Price</p><p>{calculateAvgCost(product)?.toLocaleString('en-IN', { maximumFractionDigits: 2 })}</p></div>
                <div className="detail"><p>Current NAV</p>
                  <p>{product.currentNav?.toLocaleString('en-IN', { maximumFractionDigits: 2 })}</p>
                </div>
                <div className="detail"><p>Current Value</p><p>{calculateCurrentValue(product)?.toLocaleString('en-IN', { maximumFractionDigits: 2 })}</p></div>
                <div className="detail"><p><b>Profit / Loss</b></p><p>{calculatePL(product)?.toLocaleString('en-IN', { maximumFractionDigits: 2 })}</p></div>
              </div>
            </div>
          )
        })}
        <div className="hide-on-download">
          <button onClick={() => handleRowClick()} style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}><img src={lasthistory} alt="history" height="20px" /> View All Buy/Sell</button>
        </div>
      </div>
    </>
  )
}