import { useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import LoginCard from "./LoginCard";
import upArrow from '../images/upArrow.svg';
import downArrow from '../images/downArrow.svg';
export default function NavFloatProfile() {
    const { currentUser } = useAuth();
    const [showLoginCard, setShowLoginCard] = useState(false);

    const getInitials = (username) => {
        if (!username || typeof username !== 'string') {
            return '';  // Return an empty string if username is null, undefined, or not a string
        }
        const words = username.trim().split(' ');  // Split the username by spaces
        const firstLetter = words[0][0].toUpperCase();  // First letter of the first word
        const secondLetter = words.length > 1 ? words[1][0].toUpperCase() : '';  // First letter after space
        return firstLetter + secondLetter;
    };

    return (
        <>
            <span onClick={() => setShowLoginCard(!showLoginCard)} className='login-tab'>
                <b>{currentUser ? <span className='profile-initials'>{getInitials(currentUser?.displayName)}</span> : "Login"}</b>{showLoginCard ? <img src={upArrow} alt="up" height="20px" /> : <img src={downArrow} alt='down' height="20px" />}
            </span>

            <LoginCard showLoginCard={showLoginCard} />
        </>
    );
}