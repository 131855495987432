import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import Logo from '../components/Logo';

function History() {
    const location = useLocation();
    const { userData } = location?.state || {};  // Accessing passed user data
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };

    const filterByDateRange = (date) => {
        const entryDate = new Date(date);
        const start = startDate ? new Date(startDate) : null;
        const end = endDate ? new Date(endDate) : null;

        if (start && end) {
            return entryDate >= start && entryDate <= end;
        } else if (start) {
            return entryDate >= start;
        } else if (end) {
            return entryDate <= end;
        } else {
            return true; // No filtering applied
        }
    };

    const downloadRef = useRef(null);
    
    const downloadPdf = useReactToPrint({
        content: () => downloadRef.current,
        documentTitle: 'Statements',
        onBeforeGetContent: () => {
            const addPadding = document.querySelector('.scroll-table');
            addPadding.style.padding = '5px 15px';
            const elementsToHide = document.querySelectorAll('.hide-on-download');
            elementsToHide.forEach(element => element.classList.add('hidden'));
            const elementCenter = document.querySelectorAll('.logo');
            elementCenter.forEach(element => element.classList.add('logo-center'));
        },
        onAfterPrint: () => {
            const addPadding = document.querySelector('.scroll-table');
            addPadding.style.padding = '0';
            const elementsToHide = document.querySelectorAll('.hide-on-download');
            elementsToHide.forEach(element => element.classList.remove('hidden'));
            const elementCenter = document.querySelectorAll('.logo');
            elementCenter.forEach(element => element.classList.remove('logo-center'));
        },
        onPrintError: (error) => {
            alert('Error while downloading the statements', error);
        }

      });

      const calculateAvgCost = (product) => {
        return product.purchaseCost / product.balanceUnit ;
      };

      const calculatePL = (product) => {
        const currentValue = product.balanceUnit * product.currentNav;
        const initialValue = product.balanceUnit * calculateAvgCost(product);
        return currentValue - initialValue;
      };

    return (
        <div className="container" ref={downloadRef}>
            <div className="logo">
            <Logo/>
            </div>
            <div className="date-filters hide-on-download">
                <label>
                    Start Date:
                </label>
                    <input type="date" value={startDate} onChange={handleStartDateChange} />
                <label>
                    End Date:
                </label>
                    <input type="date" value={endDate} onChange={handleEndDateChange} />
                    <button onClick={downloadPdf}>Download as PDF</button>
            </div>
            <div className="scroll-table">
                {(startDate && endDate) && (<p style={{marginTop: "15px"}}>STATEMENTS from {startDate} to {endDate}</p>)}
                <table className="users-table">
                    <thead>
                        <tr>
                            <th>DATE</th>
                            <th>product name</th>
                            <th>Bal. Unit</th>
                            <th>Avg. Cost</th>
                            <th>Pur. Cost</th>
                            <th>Curr. Nav</th>
                            <th>Curr. Val</th>
                            <th>Profit/Loss</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(userData?.history || {})
                            .filter(filterByDateRange)
                            .sort((a, b) => new Date(b) - new Date(a))
                            .map(date => {
                                const entries = userData.history[date];
                                return entries.map((entry, index) => (
                                    <tr key={`${date}-${index}`}>
                                        <td>{date}</td>
                                        <td>{entry.productName.length > 10 ? `${entry.productName.slice(0, 10)}...` : entry.productName}</td>
                                        <td>{entry.balanceUnit.toLocaleString('en-IN', { maximumFractionDigits: 2 })}</td>
                                        <td>{calculateAvgCost(entry).toLocaleString('en-IN', { maximumFractionDigits: 2 })}</td>
                                        <td>{entry.purchaseCost.toLocaleString('en-IN', { maximumFractionDigits: 2 })}</td>
                                        <td>{entry.currentNav.toLocaleString('en-IN', { maximumFractionDigits: 2 })}</td>
                                        <td>{(entry.currentNav * entry.balanceUnit).toLocaleString('en-IN', { maximumFractionDigits: 2 })}</td>
                                        <td>{calculatePL(entry).toLocaleString('en-IN', { maximumFractionDigits: 2 })}</td>
                                    </tr>
                                ));
                            })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default History;