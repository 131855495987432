import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "./HoldingDetails.css";
export default function NavGraph({schemeCode}){
  const [navData, setNavData] = useState([]);
  const [timing, settiming] = useState(7);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch NAV data from MFAPI
  useEffect(() => {
    const fetchNavData = async () => {
      try {
        const response = await fetch(`https://api.mfapi.in/mf/${schemeCode}`); // Example scheme code
        const data = await response.json();
        const navs = data.data?.slice(0, timing).reverse(); // Limit to 10 records and reverse order
        const formattedNavData = navs.map((item) => ({
          date: item.date,
          nav: parseFloat(item.nav),
        }));
        setNavData(formattedNavData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching NAV data:", error);
        setIsLoading(false);
      }
    };

    fetchNavData();
  }, [timing, schemeCode]);

  if (isLoading) return <div className="graph-loading"><p>Loading...</p></div>;

  return (
    <>
      <ResponsiveContainer width="100%" height={300} className="chart">
        <LineChart data={navData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis domain={["auto", "auto"]} />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="nav"
            stroke="#3CD7AB"
            activeDot={{ r: 8 }}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
      <div className="duration-btns">
      <button
        onClick={() => settiming(7)}
        className={`${timing === 7 ? "active" : ""}`}
      >
        1W
      </button>
      <button
        onClick={() => settiming(30)}
        className={`${timing === 30 ? "active" : ""}`}
      >
        1M
      </button>
      <button
        onClick={() => settiming(60)}
        className={`${timing === 60 ? "active" : ""}`}
      >
        3M
      </button>
      <button
        onClick={() => settiming(180)}
        className={`${timing === 180 ? "active" : ""}`}
      >
        6M
      </button>
      <button
        onClick={() => settiming(365)}
        className={`${timing === 365 ? "active" : ""}`}
      >
        1Y
      </button>
    </div>
    </>
  );
};
