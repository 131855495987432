import NavGraph from "./NavGraph";
import "./HoldingDetails.css";

export default function HoldingDetails({ product, onClose }) {
  const calculateCurrentValue = (product) => {
    return product.balanceUnit * product.currentNav;
  };
  const calculateAvgCost = (product) => {
    return product.purchaseCost / product.balanceUnit;
  };

  const calculatePL = (product) => {
    const currentValue = product.balanceUnit * product.currentNav;
    const initialValue = product.balanceUnit * calculateAvgCost(product);
    return currentValue - initialValue;
  };

  return (
    <>
      <span onClick={onClose} className="close-popup"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
      </svg>
      </span>
    <div className="holding-details">
      <h1>Holding Details</h1>
      <h4>{product.productName}</h4>
      <b><p className="amount" style={{ color: calculatePL(product) >= 0 ? "#13BA88" : "#a72828" }}>&#8377; {calculatePL(product)?.toLocaleString('en-IN', { maximumFractionDigits: 2 })}</p></b>
      <NavGraph schemeCode={product.schemeCode} />
      <h2>Portfolio Status</h2>
      <div className="details" style={{marginBottom: "25px"}}>
      <div className="detail"><p className="detail-title">Balance Unit </p><b><p>{product.balanceUnit?.toLocaleString('en-IN', { maximumFractionDigits: 2 })}</p></b></div>
      <div className="detail"><p className="detail-title">Purchase Cost </p><b><p>{product.purchaseCost?.toLocaleString('en-IN', { maximumFractionDigits: 2 })}</p></b></div>
      <div className="detail"><p className="detail-title">Average Cost </p><b><p>{calculateAvgCost(product)?.toLocaleString('en-IN', { maximumFractionDigits: 2 })}</p></b></div>
      <div className="detail"><p className="detail-title">Current NAV </p><b><p>{product.currentNav?.toLocaleString('en-IN', { maximumFractionDigits: 2 })}</p></b></div>
      <div className="detail"><p className="detail-title">Current Value </p><b><p>{calculateCurrentValue(product)?.toLocaleString('en-IN', { maximumFractionDigits: 2 })}</p></b></div>
      </div>
    </div>
    </>
  );
};

