import { useState } from "react";
import arrowLeft from '../images/arrowLeft.svg';
import { Link, useNavigate } from "react-router-dom";
import { sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import Loader from "./Loader";
import { useAuth } from "../contexts/AuthContext";
import profile from '../images/profile.svg';
import dashboard from '../images/dashboard.svg';
import message from '../images/message.svg';

export default function LoginCard({ showLoginCard }) {
    const [showResetCard, setShowResetCard] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { currentUser, signUserOut, role } = useAuth();
    const navigate = useNavigate();

    async function handleSubmit(e) {
        e.preventDefault();
        setIsLoading(true);

        try {
            const userCredential = await signInWithEmailAndPassword(auth, formData.email, formData.pass);
            const user = userCredential.user;
            // Fetch user role from Firestore
            const docRef = doc(db, 'users', user.uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const userData = docSnap.data();
                const userRole = userData.role;

                // Navigate based on user role
                if (userRole === "admin") {
                    navigate("/admin");
                } else {
                    navigate("/user");
                }
            } else {
                alert("No such user data found!");
            }
            setIsLoading(false);

        } catch (err) {
            alert(err.message);
            setIsLoading(false);
        }
    }

    const handleReset = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        try {
            await sendPasswordResetEmail(auth, formData.reset)
            setIsLoading(false);
            alert("Password reset link sent to your email");
        } catch (err) {
            alert(err.code);
            setIsLoading(false);
        }
    }

    const [formData, setFormData] = useState({
        email: '',
        pass: '',
        reset: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    return (
        <div className={`popcard ${showLoginCard ? '' : 'nav-closed'}`}>
            {currentUser ? (
                <div className="logged-user">
                    <h3>Welcome, {currentUser?.displayName}</h3>
                    <ul className="profile">
                        <li><Link to="/profile"><img src={profile} alt="profile" height="20px"/>Profile</Link></li>
                        <li><Link to={role === "admin" ? "/admin" : "/user"}><img src={dashboard} alt="dashboard" height="20px"/>Portfolio</Link></li>
                        <li><a href="#query"><img src={message} alt="message" height="20px"/>Message to Admin</a></li>
                    </ul>
                    <button onClick={signUserOut} className='btn logout'>Sign Out</button>
                </div>
            ) : (
                !showResetCard ? (
                    <div>
                        <h3>Login</h3>
                        <form onSubmit={handleSubmit}>
                            <input
                                type="email"
                                placeholder='Email'
                                name="email"
                                onChange={handleChange}
                                required
                            />
                            <input
                                type="password"
                                placeholder='Password'
                                name="pass"
                                onChange={handleChange}
                                required
                            />
                            <span className="reset" onClick={() => setShowResetCard(true)}>
                                Forgot password?
                            </span>
                            <button type="submit" className='btn' disabled={isLoading}>
                                {isLoading ? (
                                    <Loader />
                                ) : (
                                    "Submit"
                                )}
                            </button>
                        </form>
                    </div>
                ) : (
                    /* Reset Password Popcard */
                    <div>
                        <h3>Reset Password</h3>
                        <form onSubmit={handleReset}>
                            <input
                                type="email"
                                placeholder='Enter your email'
                                name="reset"
                                onChange={handleChange}
                                required
                            />
                            <span>We'll send a reset link to your email id</span>
                            <button type="submit" className='btn' disabled={isLoading}>
                                {isLoading ? (
                                    <Loader />
                                ) : (
                                    "Send"
                                )}
                            </button>
                        </form>
                        <span className="back" onClick={() => setShowResetCard(false)}>
                            <img src={arrowLeft} alt='back' height="20px" /> Back to login
                        </span>
                    </div>
                )
            )}

        </div>
    )
}