import React from 'react';
import { useLocation } from 'react-router-dom';
import User from './User';

const UserProfile = () => {
  const location = useLocation();
  const { user } = location?.state || {};  // Accessing passed user data

  return (
    <div>
     <User user={user}/>
    </div>
  );
};

export default UserProfile;
