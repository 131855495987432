import React, { useEffect, useState } from 'react';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { collection, doc, getDocs, setDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Loader from '../components/Loader';
import Logo from '../components/Logo';

export default function Admin() {
  const [email, setemail] = useState('');
  const [pass, setPass] = useState('');
  const [username, setUsername] = useState('');
  const [userPan, setUserPan] = useState('');
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchUser, setSearchUser] = useState("");
  const [showPassword, setShowPassword] = useState(false);


  const { signUserOut } = useAuth();
  const handleRegister = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, pass);
      const user = userCredential.user;
      await updateProfile(user, { displayName: username });
      // Register new user in Firestore
      const userProfile = {
        email: user.email,
        username,
        userPan,
        userID: user.uid,
        lastUpdatedOn: new Date(),
        role: "user",
        products: {},
      };

      // Store the user profile using the user's UID
      await setDoc(doc(db, 'users', user.uid), userProfile);
      setIsLoading(false);
      alert('User registered successfully');
    } catch (error) {
      alert(error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const usersCollection = collection(db, 'users');
        const usersSnapshot = await getDocs(usersCollection);
        const usersList = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setUsers(usersList);
      } catch (error) {
        console.error("Error fetching users: ", error);
      }
    };

    fetchAllUsers();
  }, []);

  const navigate = useNavigate();

  const handleRowClick = (user) => {
    navigate(`/user/${user.username}`, { state: { user } });
  };

  return (
    <div className='container'>
      <Logo />
      <div className='admin-page'>
      <form onSubmit={handleRegister}>
        <div className="form-header"><h2>Register a new client</h2></div>
        <div className="input-field">
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
          />
        </div>
        <div className="input-field">
          <input
            type="email"
            value={email}
            onChange={(e) => setemail(e.target.value)}
            placeholder="email"
          />
        </div>
        <div className="input-field">
          <input
            type="text"
            value={userPan}
            onChange={(e) => setUserPan(e.target.value)}
            placeholder="PAN"
          />
        </div>
        <div className="input-field">
          <input type={showPassword ? "text" : "password"} id="userpass" onChange={(e) => setPass(e.target.value)} placeholder='password' />
          <span className="icon" onClick={() => setShowPassword(!showPassword)}>
            {!showPassword ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
            </svg>
              : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
              </svg>
            }
          </span>
        </div>
        <button type="submit">
          {isLoading ? <Loader /> : 'Register'}
        </button>
      </form>
      <div className="clients-container">
        <div className="search-bar">
        <h2>All Clients</h2>
        <div className="input-field">
          <input type='text' placeholder='search with name or email' onChange={(e) => setSearchUser(e.target.value)} />
        </div>
        </div>
      <div className='clients'>
        <div className="scroll-table">
          {users.length > 0 ? (
            <table className="users-table">
              <thead>
                <tr>
                  <th>Investor</th>
                  <th>Email</th>
                  <th>PAN</th>
                </tr>
              </thead>
              <tbody>
                {users
                  .filter((user) =>
                    (user.username?.toLowerCase().includes(searchUser.toLowerCase()) ||
                      user.email?.toLowerCase().includes(searchUser.toLowerCase())) &&
                    user.role === "user"
                  )
                  .map(user => (
                    <tr key={user.id} onClick={() => handleRowClick(user)}>
                      <td>{user.username}</td>
                      <td>{user.email}</td>
                      <td>{user.userPan}</td>
                    </tr>
                  ))}

              </tbody>
            </table>
          ) : (
            <p>No users found.</p>
          )}
        </div>
      </div>
      </div>

      </div>

      <button onClick={signUserOut}>Sign out</button>
    </div>
  )
}