import { useEffect, useState } from "react";
import SecLogo from "../components/secLogo";
import { useAuth } from "../contexts/AuthContext";
import { updateProfile } from "firebase/auth";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import Loader from "../components/Loader";

export default function Profile() {
  const { currentUser } = useAuth();
  const [editedValues, setEditedValues] = useState({
    username: '',
    email: '',
    pan: '', // Assume PAN is fetched elsewhere if needed
    number: '', // Assume Mobile number is fetched elsewhere if needed
  });
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!currentUser) return; // Exit if no currentUser
    const unsubscribe = onSnapshot(
      doc(db, 'users', currentUser.uid),
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          setEditedValues({
            username: currentUser.displayName || '',
            email: currentUser.email || '',
            pan: userData?.userPan || '',
            number: userData?.number || ''
          });
        } else {
          console.error("No such user data found!");
        }
        setIsLoading(false);
      },
      (error) => {
        console.error("Error fetching user data:", error);
        setIsLoading(false);
      }
    );

    return () => unsubscribe(); // Cleanup the listener on unmount
  }, [currentUser]);

  const handleUpdateProfile = async (e) => {
    e.preventDefault(); // Prevent form submission reload

    try {
      setIsLoading(true);
      await updateProfile(currentUser, { displayName: editedValues?.username, email: editedValues?.email });
      await updateDoc(doc(db, 'users', currentUser.uid),{
        userPan: editedValues?.pan,
        number: editedValues?.number
      });
      alert('Profile updated successfully');
      setIsLoading(false);
      setIsEditing(false);
      
    } catch (error) {
      console.error("Error updating profile: ", error);
      setIsLoading(false);
    }
  };

  return (
    <div style={{ padding: "5px" }}>
      <SecLogo />
      <div className="container"
        style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
        <div className="profile-card">
          <h1>Profile</h1>
          {isLoading && <div className="loading-state">
            <Loader />
          </div>}
          <form onSubmit={handleUpdateProfile}>
            {/* Username */}
            {isEditing ? (
              <input
                type="text"
                placeholder={currentUser?.displayName || "Username"}
                value={editedValues?.username}
                onChange={(e) => setEditedValues({ ...editedValues, username: e.target.value })}
              />
            ) : (
              <p>Username: {currentUser?.displayName}</p>
            )}

            {/* Email */}
            {isEditing ? (
              <input
                type="email"
                placeholder={currentUser?.email || "Email"}
                value={editedValues?.email}
                onChange={(e) => setEditedValues({ ...editedValues, email: e.target.value })}
              />
            ) : (
              <p>Email: {currentUser?.email}</p>
            )}

            {/* PAN */}
            {isEditing ? (
              <input
                type="text"
                placeholder="PAN Number"
                value={editedValues?.pan}
                onChange={(e) => setEditedValues({ ...editedValues, pan: e.target.value })}
              />
            ) : (
              <p>PAN: {editedValues?.pan || 'Not provided'}</p>
            )}

            {/* Mobile Number */}
            {isEditing ? (
              <input
                type="tel"
                placeholder="Mobile Number"
                maxLength={10}
                value={editedValues?.number}
                onChange={(e) => setEditedValues({ ...editedValues, number: e.target.value })}
              />
            ) : (
              <p>Mobile Number: {editedValues?.number || 'Not provided'}</p>
            )}

            {isEditing && (
              <div style={{ display: "flex", gap: "5px", justifyContent: "flex-end" }}>
                <button type="submit">{isLoading ? "Updating..." : "Update"}</button>
              </div>
            )}
          </form>
          {!isEditing && <button type="button" onClick={() => setIsEditing(!isEditing)}>Edit</button>}
        </div>
      </div>
    </div>
  );
}
