import React, { useState } from "react";
import './SipCalculator.css';
import { useRef, useEffect } from 'react';
const SIPCalculator = () => {
  const [monthlyInvestment, setMonthlyInvestment] = useState(10000);
  const [annualRate, setAnnualRate] = useState(12);
  const [duration, setDuration] = useState(3);

  // Calculate total earnings using formula for SIP returns
  const calculateSIP = () => {
    const monthlyRate = annualRate / 12 / 100;
    const months = duration * 12;
    const futureValue = monthlyInvestment * (((1 + monthlyRate) ** months - 1) / monthlyRate) * (1 + monthlyRate);
    return futureValue;
  };

  // Calculate the profit
  const calculateProfit = () => {
    const totalInvested = monthlyInvestment * duration * 12;
    return (calculateSIP() - totalInvested);
  };
  const monthlyInvestmentRef = useRef(null);
  const annualRateRef = useRef(null);
  const durationRef = useRef(null);

  useEffect(() => {
    const updateSliderBackground = (sliderRef, value) => {
      const slider = sliderRef.current;
      const percent = ((value - slider.min) / (slider.max - slider.min)) * 100;
      slider.style.backgroundSize = `${percent}% 100%`;
    };

    updateSliderBackground(monthlyInvestmentRef, monthlyInvestment);
    updateSliderBackground(annualRateRef, annualRate);
    updateSliderBackground(durationRef, duration);
  }, [monthlyInvestment, annualRate, duration]);

  return (
    <div className="sip-calculator">
      <h2>SIP Calculator</h2>

      {/* Monthly Investment Slider */}
      <div className="slider-group">
        <div className="slider-label">
        <label>Monthly Investment</label>
        <span>₹ <input type="number" value={monthlyInvestment} onChange={(e) => setMonthlyInvestment(e.target.value)} className="edit-input"/></span>
        </div>
        <input
          type="range"
          min="1000"
          max="50000"
          value={monthlyInvestment}
          onChange={(e) => setMonthlyInvestment(e.target.value)}
          className="range-slider"
          ref={monthlyInvestmentRef}
        />
      </div>

      {/* Annual Rate of Return Slider */}
      <div className="slider-group">
      <div className="slider-label">
        <label>Annual Rate of Return</label>
      <span>{annualRate} %</span>

      </div>
        <input
          type="range"
          min="1"
          max="30"
          value={annualRate}
          onChange={(e) => setAnnualRate(e.target.value)}
          className="range-slider"
          ref={annualRateRef}
        />
      </div>

      {/* Investment Duration Slider */}
      <div className="slider-group">
      <div className="slider-label">
        <label>Investment Duration</label>
      <span>{duration} years</span>

      </div>
        <input
          type="range"
          min="1"
          max="30"
          value={duration}
          onChange={(e) => setDuration(e.target.value)}
          className="range-slider"
          ref={durationRef}
        />
      </div>

      {/* Output */}
      <div className="output">
        <p>
          You will earn <br /><span>₹{calculateSIP().toLocaleString('en-IN', { maximumFractionDigits: 2 })}</span>
        </p>
        <p className="profit">
          profit of ₹{calculateProfit().toLocaleString('en-IN', { maximumFractionDigits: 2 })}
        </p>
      </div>

    </div>
  );
};

export default SIPCalculator;
