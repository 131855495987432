import React, { useContext, createContext, useEffect, useState } from "react";
import { auth, db } from '../firebase';
import { onAuthStateChanged, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [role, setRole] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const signUserOut = async () => {
  try {
    await signOut(auth);
    // localStorage.removeItem("isAuth");
    navigate("/");
  } catch (err) {
    setError(err.message);
  }
};

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setRole(userDoc.data().role);
        }
      } else {
        setRole(null);
      }
    });

    return unsubscribe;
  }, []);


  const value = {
    currentUser,
    error,
    role,
    signUserOut,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
