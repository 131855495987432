import Footer from "../components/Footer";
import SecLogo from "../components/secLogo";

export default function TnC() {
    return (
        <>
        <div className="container">
                <SecLogo/>
            <div className="tnc-container">

            <div className="tnc">

            <h1>Terms and Conditions</h1>
            <p>These Terms and Conditions govern the use of the RoyFinds website, operated and managed by Dipak Roy, a registered mutual fund distributor. By accessing or using the website, you agree to comply with and be bound by these Terms. Please review them carefully.</p>

            <h2>1. Service Scope</h2>
            <p>The RoyFinds platform is designed to help investors:</p>
            <ol>
                <li>Track their mutual fund portfolio</li>
                <li>Connect with the distributor for financial advice</li>
            <li>No transactions involving the payment or purchase of mutual funds are conducted on this platform. All transactions are to be done through approved channels.</li>
            </ol>

            <h2>2. No Payment or Investment Transactions</h2>
            <p>RoyFinds does not facilitate any online investment transactions or payments. The platform is purely informational, allowing investors to:</p>
            <ol>
                <li>Monitor their mutual fund portfolio</li>
                <li>Seek advice and guidance on mutual fund investments</li>
            <li>Any payments or investment transactions should be done through official mutual fund distributors, AMCs (Asset Management Companies), or authorized agents, not through this website.</li>
            </ol>

            <h2>3. Investment Risks</h2>
            <p>Mutual fund investments are subject to market risks. Investors should:</p>
            <ol>
                <li>Read all scheme-related documents carefully before investing.</li>
                <li>Understand that the NAVs of schemes can fluctuate based on market factors, including interest rate changes.</li>
                <li>Be aware that past performance is not indicative of future returns.</li>
                <li>Seek professional advice to understand specific legal, financial, and tax implications before investing.</li>
            </ol>

            <h2>4. No Guarantee of Returns</h2>
            <p>RoyFinds does not guarantee any specific return on investments or the declaration of dividends. The performance of mutual funds is subject to market risks, and the availability of distributable surplus will dictate dividend payments, if any.</p>

            <h2>5. User Responsibilities</h2>
            <p>By using the RoyFinds platform, users agree to:</p>
            <ol>
                <li>Use the site for personal, non-commercial purposes only.</li>
                <li>Provide accurate and up-to-date information when interacting with the platform.</li>
                <li>Not misuse or engage in fraudulent activities on the site.</li>
            </ol>

            <h2>6. Privacy Policy</h2>
            <p>RoyFinds respects user privacy and does not share personal information with third parties without consent. For details on how your information is collected and used, please refer to our Privacy Policy.</p>

            <h2>7. Limitation of Liability</h2>
            <p>RoyFinds, including its owner <strong>Dipak Roy</strong>, shall not be held liable for:</p>
            <ol>
                <li>Any loss of capital or financial loss resulting from investments in mutual funds.</li>
                <li>Technical issues with the platform that cause inaccuracies in portfolio tracking.</li>
                <li>Advice provided through the platform, as it is the responsibility of the investor to consult professional financial advisors before making investment decisions.</li>
            </ol>

            <h2>8. Intellectual Property</h2>
            <p>All content, design, text, and graphics on RoyFinds are the intellectual property of <strong>Dipak Roy</strong> unless otherwise stated. Unauthorized use, reproduction, or distribution of any materials on this website is prohibited.</p>

            <h2>9. Amendments</h2>
            <p>RoyFinds reserves the right to modify these Terms and Conditions at any time. Changes will be effective upon being posted on the website. Users are encouraged to review the terms regularly to ensure they are aware of any updates.</p>

            <h2>10. Governing Law</h2>
            <p>These Terms and Conditions are governed by and construed in accordance with the laws of India. Any disputes arising out of or related to the use of this platform shall be subject to the jurisdiction of the courts of India.</p>
        </div>
        </div>

            </div>
            <Footer/>
            </>
    );
}