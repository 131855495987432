import Footer from "../components/Footer";
import SecLogo from "../components/secLogo";

export default function Disclaimer() {
    return (
        <>
        <div className="container">
            <SecLogo/>
            <div className="disclamier-container">

            <div className="disclamier">
            <h1>Disclaimer</h1>
            <p>
            Mutual Fund investments are subject to market risks. Please read
            all scheme-related documents carefully before investing. The Net
            Asset Values (NAVs) of the schemes may fluctuate based on factors
            affecting the securities market, including changes in interest
            rates. Past performance of mutual funds is not indicative of future
            performance. The Mutual Fund does not guarantee or assure dividends
            under any of the schemes, and any such dividends are subject to the
            availability and adequacy of distributable surplus.
            <br />
            <br />
            This website is operated by RoyFinds, fully owned and managed by
            Dipak Roy, a mutual fund distributor. The platform is designed to
            help investors track their portfolio and stay connected with me. It
            is not intended for transactions involving payments or investments
            directly through the website. Investors are advised to consult
            expert financial, legal, or tax advisors for specific advice
            regarding their participation in mutual fund schemes. No payments
            are to be made through this site for any services or investments.
          </p>
            </div>
            </div>
        </div>
        <Footer/>
        </>
    );
}
